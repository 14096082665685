import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logoImg from '../../assets/img/logo.svg';
import routes from '../../constants/routesConstants';
import ThemeContext from '../../context/themeContext';

const NavbarTwo = () => {
  const themeCtx = useContext(ThemeContext);
  return (
    <div className={`theme-navbar-2 ${themeCtx.isNavbarOpen ? 'show' : ''}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col">
            <div className="theme-logo">
              <Link to={routes.Home}>
                <img src={logoImg} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-auto">
            <div
              className="theme-navbar-2-menu-icon"
              onClick={() => themeCtx.toggleNavbar()}
            >
              <span></span>
            </div>
            <div className="theme-navbar-2-wrap">
              <div className="theme-navbar-2-nav">
                <ul>
                  <li>
                    <Link to={routes.Marketplace}>Marketplace</Link>
                  </li>
                  <li>
                    <a href="#fantasy-sports-sec">Fantasy Sports</a>
                  </li>
                  <li>
                    <a href="#p2p-games-sec">Card Games</a>
                  </li>
                  <li>
                    <a href="#simulation-games-sec">Sports Simulation</a>
                  </li>
                  <li>
                    <Link to={routes.StakingRewards}>Airdrop</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarTwo;
