import React, { createContext, useState } from 'react';

const ThemeContext = createContext({
  reload: 0,
  reloadHandler: () => {},
  isNavbarOpen: false,
  toggleNavbar: () => {},
});

export const ThemeContextProvider = (props) => {
  const [reload, setReload] = useState(0);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const reloadHandler = () => {
    setReload((v) => v + 1);
  };

  const toggleNavbar = () => {
    if (!isNavbarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    setIsNavbarOpen(!isNavbarOpen);
  };

  const contextValue = {
    reload: reload,
    reloadHandler: reloadHandler,
    isNavbarOpen: isNavbarOpen,
    toggleNavbar: toggleNavbar,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
