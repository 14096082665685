import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';
import Countdown, { zeroPad } from 'react-countdown';

import trophyImg from '../../assets/img/svg/trophy.svg';
import useGetNFTDetails from '../../hook/useGetNFTDetails';
import useToggleFavNFT from '../../hook/useToggleFavNFT';

import usePutOnMarketplace from '../../hook/usePutOnMarketplace';
import { Formik } from 'formik';
import moment from 'moment/moment';
import { NFT_SALE_TYPES } from '../../constants/contractConstants';
import useApproveNFT from '../../hook/useApproveNFT';
import useBuyNFT from '../../hook/useBuyNFT';
import {
  formatEther,
  marketPlaceNFTStatus,
  marketPlaceNFTType,
} from '../../utils/etherConversionUtils';
import useRemoveFromSale from '../../hook/useRemoveFromSale';
import usePutOfferOnSale from '../../hook/usePutOfferOnSale';
import usePutBidOnAuction from '../../hook/usePutBidOnAuction';
import useSetWinnerForAuction from '../../hook/useSetWinnerForAuction';
import OfferList from './components/offers/OfferList';
import BidList from './components/bids/BidList';
import TransactionHistory from './components/TransactionHistory/TransactionHistory';
import useGetNFTOnRent from '../../hook/useGetNFTOnRent';

const flags = {
  SL: '/images/Flags/SL.png',
  WI: '/images/Flags/WI.png',
  AFG: '/images/Flags/AFG.png',
  AUS: '/images/Flags/AUS.png',
  BAN: '/images/Flags/BAN.png',
  ENG: '/images/Flags/ENG.png',
  IND: '/images/Flags/IND.png',
  NZ: '/images/Flags/NZ.png',
  PAK: '/images/Flags/PAK.png',
  SA: '/images/Flags/SA.png',
};

const categoryClasses = {
  Legendary: 'legendary',
  Rare: 'rare',
  'Super Rare': 'rare',
  Common: 'common',
};

const Nft = () => {
  const [completed, setCompleted] = useState(false);
  const [offerForm, setOfferForm] = useState(false);

  const params = useParams();
  const { details, loading, doRefresh, userData } = useGetNFTDetails(
    params?.id
  );
  const { name, description } = details?.meta ?? {};
  const {
    nftId,
    metaData,
    user,
    marketplaceDetails,
    no,
    category,
    country,
    roles,
    currentOwner,
  } = details ?? {};

  console.log({ marketplaceDetails });
  const image = nftId
    ? process.env.REACT_APP_MODE === 'test'
      ? `https://apix.cubixpro.io/nfts_test/assets/${nftId}.jpg`
      : `https://apix.cubixpro.io/nfts/assets/${nftId}.jpg`
    : null;

  const { doToggle } = useToggleFavNFT(doRefresh);

  const liked = details?.liked;
  const likes = details?.likes;

  const status = marketPlaceNFTType?.[marketplaceDetails?.typeOfSale];
  const isOwner =
    userData?.userData?.accountAddress &&
    userData?.userData?.accountAddress?.toLowerCase() ===
      currentOwner?.toLowerCase();
  // **************************** add to marketplace ****************************
  const { putOnMarketplace, loading: puttingOnSale } =
    usePutOnMarketplace(doRefresh);
  const { approve, loading: approving, isApproved } = useApproveNFT(nftId);
  // **************************** add to marketplace done  ****************************

  // **************************** buy NFT ****************************
  const { buyNFT, loading: buyingNFT } = useBuyNFT(doRefresh);
  // **************************** buy NFT done  ****************************

  // **************************** rent NFT ****************************
  const { rentNFT, loading: rentingNFT } = useGetNFTOnRent(doRefresh);
  // **************************** rent NFT done  ****************************

  // **************************** remove NFT ****************************
  const { removeFromSale, loading: removing } = useRemoveFromSale(doRefresh);
  // **************************** remove NFT done  ****************************

  // **************************** place offer NFT ****************************
  const {
    putOfferOnSale,
    loading: offerPlacing,
    approving: approvingForOffer,
  } = usePutOfferOnSale(doRefresh);
  // **************************** place offer NFT done  ****************************

  // **************************** place offer NFT ****************************
  const {
    putBidOnSale,
    loading: bidPlacing,
    approving: approvingForBid,
  } = usePutBidOnAuction(doRefresh);
  console.log({ bidPlacing, approvingForBid });
  // **************************** place offer NFT done  ****************************

  // **************************** accept NFT Offer ****************************
  const { setWinner, loading: settingWinner } =
    useSetWinnerForAuction(doRefresh);
  // **************************** accept NFT offer done  ****************************

  let roleImage;

  if (roles?.includes('Bowler')) {
    roleImage = `/images/Bowler.png`;
  }

  if (roles?.includes('Batsman')) {
    roleImage = `/images/Batsman.png`;
  }

  if (roles?.includes('Allrounder')) {
    roleImage = `/images/AllRounder.png`;
  }

  if (roles?.includes('WK')) {
    roleImage = `/images/WicketKeeper.png`;
  }

  if (loading) {
    return <div className="loader">Loading...</div>;
  }

  const shareLink = window.location.href;

  const getButtonText = () => {
    if (approving) {
      return 'Please wait...';
    }
    if (puttingOnSale) {
      return 'Please wait...';
    }
    if (isApproved) {
      return 'Add to Marketplace';
    }
    return 'Approve NFT';
  };

  const _renderRentNFTForm = () => {
    if (status === marketPlaceNFTStatus?.ON_RENT && !isOwner) {
      return (
        <Formik
          initialValues={{
            days: 0,
          }}
          onSubmit={(values) => {
            rentNFT(nftId, values.days);
          }}
        >
          {({ values, handleChange, handleSubmit }) => {
            return (
              <div>
                <div className="row mt-4">
                  <div className="col-sm-6">
                    <label>Days</label>
                    <input
                      className="form-control"
                      placeholder="Enter days"
                      name="days"
                      value={values.days}
                      onChange={handleChange}
                      type="number"
                    />
                  </div>

                  <div className="col-sm-12 mt-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                      disabled={rentingNFT}
                    >
                      {rentingNFT ? 'Please wait...' : 'Rent NFT'}
                    </button>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      );
    }
    return null;
  };

  const _renderBuyNowAndMakeOfferButtons = () => {
    if (status === marketPlaceNFTStatus?.ON_SALE && !isOwner) {
      return (
        <div className="nft-detail-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              if (!buyingNFT) {
                buyNFT(nftId);
              }
            }}
          >
            {buyingNFT ? 'Buying for' : 'Buy for'}{' '}
            {formatEther(String(marketplaceDetails?.price ?? '0'))} CUBIX
          </button>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => setOfferForm(!offerForm)}
          >
            Make Offer
          </button>
        </div>
      );
    }
    return null;
  };

  const _renderPlaceOffer = () => {
    if (offerForm) {
      return (
        <Formik
          initialValues={{
            price: 0,
          }}
          onSubmit={(values) => {
            putOfferOnSale(marketplaceDetails?.id, nftId, values.price);
          }}
        >
          {({ values, handleChange, handleSubmit }) => {
            return (
              <div>
                <div className="row mt-4">
                  <div className="col-sm-6">
                    <label>Price</label>
                    <input
                      className="form-control"
                      placeholder="Enter price"
                      name="price"
                      value={values.price}
                      onChange={handleChange}
                      type="number"
                    />
                  </div>

                  <div className="col-sm-12 mt-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                      disabled={puttingOnSale}
                    >
                      {offerPlacing || approvingForOffer
                        ? 'Please wait...'
                        : 'Place Offer'}
                    </button>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      );
    }
    return null;
  };

  const _renderPlaceBid = () => {
    if (status === marketPlaceNFTStatus.ON_AUCTION && !isOwner) {
      return (
        <Formik
          initialValues={{
            price: 0,
          }}
          onSubmit={(values) => {
            putBidOnSale(marketplaceDetails?.id, nftId, values.price);
          }}
        >
          {({ values, handleChange, handleSubmit }) => {
            return (
              <div>
                <div className="row mt-4">
                  <div className="col-sm-6">
                    <label>Price</label>
                    <input
                      className="form-control"
                      placeholder="Enter price"
                      name="price"
                      value={values.price}
                      onChange={handleChange}
                      type="number"
                    />
                  </div>

                  <div className="col-sm-12 mt-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                      disabled={bidPlacing}
                    >
                      {bidPlacing || approvingForBid
                        ? 'Please wait...'
                        : 'Place Bid'}
                    </button>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      );
    }
    return null;
  };
  const _renderAddToMarketplace = () => {
    if (
      isOwner &&
      [marketPlaceNFTStatus.NOT_ON_SALE, marketPlaceNFTStatus.REMOVED].includes(
        status
      )
    ) {
      return (
        <Formik
          initialValues={{
            price: 0,
            typeOfSale: '',
            auctionEndDate: null,
            perDayRent: 0,
          }}
          onSubmit={(values) => {
            if (isApproved) {
              putOnMarketplace(
                nftId,
                values.price,
                values.typeOfSale,
                values.auctionEndDate
                  ? moment(values.auctionEndDate)
                      .endOf('date')
                      .diff(moment(), 'days')
                  : '0',
                false,
                values.perDayRent
              );
            } else {
              approve(nftId);
            }
          }}
        >
          {({ values, handleChange, handleSubmit }) => {
            return (
              <div>
                <div className="row mt-4">
                  <div className="col-sm-6">
                    <label>Add To Marketplace as</label>
                    <select
                      className="form-control"
                      placeholder="Select date"
                      name="typeOfSale"
                      value={values.typeOfSale}
                      onChange={handleChange}
                    >
                      <option>Select</option>
                      {NFT_SALE_TYPES.map((type) => {
                        if (
                          type.key === marketPlaceNFTStatus.REMOVED ||
                          marketplaceDetails?.id !== 0
                        ) {
                          return null;
                        }
                        return (
                          <option value={type.index} key={type.index}>
                            {type.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {String(values?.typeOfSale) === '1' ||
                  String(values?.typeOfSale) === '2' ? (
                    <div className="col-sm-6">
                      <label>Price</label>
                      <input
                        className="form-control"
                        placeholder="Enter price"
                        name="price"
                        value={values.price}
                        onChange={handleChange}
                      />
                    </div>
                  ) : null}
                  {String(values?.typeOfSale) === '2' ? (
                    <div className="col-sm-6">
                      <label>End Date</label>
                      <input
                        className="form-control"
                        placeholder="Select date"
                        name="auctionEndDate"
                        value={values.auctionEndDate}
                        onChange={handleChange}
                        type="date"
                      />
                    </div>
                  ) : null}
                  {String(values?.typeOfSale) === '3' ? (
                    <div className="col-sm-6">
                      <label>Per Day Rent</label>
                      <input
                        className="form-control"
                        placeholder="Enter per day rent"
                        name="perDayRent"
                        value={values.perDayRent}
                        onChange={handleChange}
                      />
                    </div>
                  ) : null}
                  {values.typeOfSale ? (
                    <div className="col-sm-12 mt-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                        disabled={puttingOnSale}
                      >
                        {getButtonText()}
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          }}
        </Formik>
      );
    }

    return null;
  };

  const _renderRemoveOffer = () => {
    return [
      marketPlaceNFTStatus?.ON_SALE,
      marketPlaceNFTStatus?.ON_AUCTION,
      marketPlaceNFTStatus?.ON_RENT,
    ]?.includes(status) && isOwner ? (
      <div className="nft-detail-footer mt-2">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            if (!removing) {
              removeFromSale(nftId);
            }
          }}
        >
          {removing ? 'Please wait' : 'Remove From Marketplace'}
        </button>
      </div>
    ) : null;
  };

  const _renderAuctionCounter = () => {
    return status === marketPlaceNFTStatus?.ON_AUCTION ? (
      <div className="">
        <div className="nft-card-timer position-relative d-flex">
          <span className="nft-card-timer-text">
            <i className="icon-timer"></i>
            {moment(marketplaceDetails?.auctionEnds * 1000).isSameOrBefore(
              moment()
            ) || completed ? (
              <>Auction Completed</>
            ) : (
              <Countdown
                date={new Date(marketplaceDetails?.auctionEnds * 1000)}
                renderer={({ days, hours, minutes, seconds }) => {
                  return `${zeroPad(days)} : ${zeroPad(hours)} : ${zeroPad(
                    minutes
                  )} : ${zeroPad(seconds)}`;
                }}
                onComplete={() => {
                  setCompleted(true);
                }}
              />
            )}
          </span>
        </div>
      </div>
    ) : null;
  };

  return (
    <>
      <div className="nft-detail-wrap">
        <div className="container">
          <div className="nft-detail-inner">
            <div className="row">
              <div className="col-lg-6">
                <div className="nft-detail-image-wrap">
                  <div className="nft-detail-image">
                    <img src={image} alt="" />
                  </div>
                  <div className="nft-detail-image-share">
                    <CopyToClipboard
                      text={shareLink}
                      onCopy={() => {
                        toast.success('Link copied!');
                      }}
                    >
                      <a href="javascript:void(0)" className="liked">
                        <i className="icon-share"></i>
                      </a>
                    </CopyToClipboard>
                    <a
                      className={liked && 'liked'}
                      href="javascript:void(0)"
                      onClick={() => doToggle(nftId)}
                    >
                      <i className="icon-favourites"></i>
                      <br />
                      &nbsp;{likes}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="nft-detail-con">
                  <h1 className="nft-detail-title">{name}</h1>
                  <span className="nft-detail-owner-name">
                    Owned by: @{user?.userName ?? user?.accountAddress}
                  </span>
                  <div className="nft-detail-rank">
                    <img src={trophyImg} alt="" />
                    <span>RANK: {no}</span>
                  </div>
                  <div className="nft-detail-type-wrap">
                    <div
                      className={`nft-detail-type ${categoryClasses?.[category]}`}
                    >
                      {category}
                    </div>
                    <div className="nft-detail-id">ID #{nftId}</div>
                  </div>
                  <h2 className="nft-detail-sub-title">Properties</h2>
                  <div className="nft-detail-properties">
                    <a href="javascript:void(0)">
                      <img
                        style={{ height: 40, width: 40 }}
                        src={roleImage}
                        alt=""
                      />
                      <span>{roles}</span>
                    </a>

                    <a href="javascript:void(0)">
                      <img
                        src={flags?.[country]}
                        alt=""
                        style={{ height: 40, width: 40 }}
                      />
                      <span>{country}</span>
                    </a>
                  </div>
                  <h2 className="nft-detail-sub-title">Details</h2>
                  <p>{description}</p>
                  {_renderAuctionCounter()}
                  {_renderRemoveOffer()}
                  {/* todo remove once offers table UI done */}
                  {/* todo remove once offers table UI done */}
                  {[marketPlaceNFTStatus?.ON_AUCTION]?.includes(status) &&
                  isOwner ? (
                    <div className="nft-detail-footer mt-2">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => {
                          if (!settingWinner) {
                            setWinner(nftId, marketplaceDetails?.id);
                          }
                        }}
                      >
                        {settingWinner ? 'Please wait' : 'Set Winner'}
                      </button>
                    </div>
                  ) : null}
                  {_renderRentNFTForm()}
                  {_renderBuyNowAndMakeOfferButtons()}
                  {_renderPlaceOffer()}
                  {_renderPlaceBid()}
                  {_renderAddToMarketplace()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nft-detail-history">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="nft-detail-history-card">
                <h3 className="nft-detail-history-title">Attributes</h3>
                <div className="nft-detail-history-attributes">
                  <ul>
                    <li>ERC-721</li>
                    <li>
                      <a
                        href={`https://polygonscan.com/token/0x6da8a67989cbecbc971d574522081df25416b057?a=${nftId}`}
                        target={'_blank'}
                      >
                        Polygon
                      </a>
                    </li>
                    <li>
                      {' '}
                      <a
                        href={
                          process.env.REACT_APP_MODE === 'test'
                            ? `https://apix.cubixpro.io/nfts/${nftId}?test=true`
                            : `https://apix.cubixpro.io/nfts/${nftId}`
                        }
                        target={'_blank'}
                      >
                        NFT
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          process.env.REACT_APP_MODE === 'test'
                            ? `https://apix.cubixpro.io/nfts_test/assets/${nftId}.jpg`
                            : metaData
                        }
                        target={'_blank'}
                      >
                        {nftId}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <TransactionHistory tokenId={nftId} />
          </div>
        </div>
      </div>

      <div className="nft-detail-history">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <OfferList
                marketplace={marketplaceDetails}
                isOwner={isOwner}
                doRefresh={doRefresh}
              />

              <BidList
                marketplace={marketplaceDetails}
                isOwner={isOwner}
                doRefresh={doRefresh}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nft;
