const routes = {
  Home: '/',
  Nft: '/nft/:id',
  Profile: '/profile',
  Favourites: '/favourites',
  Collection: '/collection',
  UserDetails: '/profile/:userName',
  Marketplace: '/marketplace',
  Staking: '/staking',
  StakingRewards: '/airdrop',
};
export default routes;
