import React, { useState } from 'react';
import nftCardsImg from '../../assets/img/two-cards.png';
import Select from '../Select';
import useGetMarketplaceNFTs from '../../hook/useGetMarketplaceNFTs';
import NFTCard from '../Shared/NFT/NFTCard';
import useGetMarketplaceMasters from '../../hook/useGetMarketplaceMasters';

const Home = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filterToggleHandler = () => {
    if (!isFilterOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    setIsFilterOpen(!isFilterOpen);
  };

  const {
    nfts,
    loading,
    doRefresh,
    skip,
    called,
    reset,
    setCategory,
    setCountry,
    category,
    country,
  } = useGetMarketplaceNFTs();

  const { masters } = useGetMarketplaceMasters();

  const records = nfts?.data?.records;

  return (
    <>
      <div className="home-banner">
        <div className="container">
          <div className="home-banner-inner">
            <div className="row align-items-center">
              <div className="col">
                <div className="home-banner-con">
                  <h1 className="sec-title">
                    Acquire, Collect, Stake, Earn and Sell Epic NFTs
                  </h1>
                </div>
              </div>
              <div className="col-sm-auto">
                <div className="home-banner-img">
                  <img src={nftCardsImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-listing-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div
                className={`home-listing-filter ${isFilterOpen ? 'open' : ''}`}
              >
                <div className="d-block d-lg-none text-end">
                  <button
                    type="button"
                    className="home-listing-filter-close-btn"
                    onClick={() => filterToggleHandler()}
                  >
                    <i className="icon-close"></i>
                  </button>
                </div>
                <div className="home-listing-filter-head">
                  <h2 className="home-listing-filter-title">Filter</h2>
                  <button type="button" className="home-listing-filter-reset">
                    Reset
                  </button>
                </div>
                <div className="accordion" id="filterAcccordion">
                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="leagueHeading">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#league"
                        aria-expanded="true"
                        aria-controls="league"
                      >
                        League
                      </button>
                    </h2>
                    <div
                      id="league"
                      className="accordion-collapse collapse show"
                      aria-labelledby="leagueHeading"
                      data-bs-parent="#filterAcccordion"
                    >
                      <div className="accordion-body">
                        <div className="home-listing-filter-checkbox-wrap">
                           <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id=""
                            />
                            <label className="form-check-label" htmlFor="">
                              Afganistan
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="playersHeading">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#players"
                        aria-expanded="false"
                        aria-controls="players"
                      >
                        Players
                      </button>
                    </h2>
                    <div
                      id="players"
                      className="accordion-collapse collapse"
                      aria-labelledby="playersHeading"
                      data-bs-parent="#filterAcccordion"
                    >
                      <div className="accordion-body"></div>
                    </div>
                  </div> */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="teamsHeading">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#teams"
                        aria-expanded="false"
                        aria-controls="teams"
                      >
                        Teams
                      </button>
                    </h2>
                    <div
                      id="teams"
                      className="accordion-collapse collapse"
                      aria-labelledby="teamsHeading"
                      data-bs-parent="#filterAcccordion"
                    >
                      <div className="accordion-body">
                        {masters?.teams?.map((team) => {
                          return (
                            <div className="home-listing-filter-checkbox-wrap">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={team}
                                  id={team}
                                  checked={country === team}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setCountry(e.target.value);
                                    } else {
                                      setCountry(null);
                                    }
                                  }}
                                />
                                <label className="form-check-label" htmlFor="">
                                  {team}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="rarityHeading">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#rarity"
                        aria-expanded="false"
                        aria-controls="rarity"
                      >
                        Rarity
                      </button>
                    </h2>
                    <div
                      id="rarity"
                      className="accordion-collapse collapse"
                      aria-labelledby="rarityHeading"
                      data-bs-parent="#filterAcccordion"
                    >
                      <div className="accordion-body">
                        {masters?.categories?.map((_category) => {
                          return (
                            <div className="home-listing-filter-checkbox-wrap">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={_category}
                                  id={_category}
                                  checked={category === _category}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setCategory(e.target.value);
                                    } else {
                                      setCategory(null);
                                    }
                                  }}
                                />
                                <label className="form-check-label" htmlFor="">
                                  {_category}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="home-listing-filter-footer">
                  <button type="button" className="btn btn-outline-primary">
                    Reset
                  </button>
                  <button type="button" className="btn btn-primary">
                    Done
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="home-listing-filters">
                <div className="row">
                  <div className="col-sm-2 col-6 order-2 order-sm-1 d-lg-none d-block">
                    <button
                      type="button"
                      className="home-listing-filter-toggle-btn"
                      onClick={() => filterToggleHandler()}
                    >
                      <i className="icon-filter"></i> Filters
                    </button>
                  </div>
                  <div className="col-lg-8 col-sm-6 col-12 order-1 order-sm-2">
                    <div className="home-listing-search">
                      <input
                        disabled
                        readOnly
                        type="text"
                        className="form-control"
                        placeholder="Search by Players/Rarity..."
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-6 order-3">
                    <div className="home-listing-sorting">
                      <Select
                        value={selectedValue}
                        options={[
                          { value: 'all', label: 'All' },
                          // { value: 'buy', label: 'Buy' },
                          // { value: 'rent', label: 'Rent' },
                          { value: 'auctionStarts-ASC', label: 'Bid' },
                          // {
                          //   value: 'rarity-high',
                          //   label: 'Rarity: High to Low',
                          // },
                          // {
                          //   value: 'ranking-low',
                          //   label: 'Ranking: Low to High',
                          // },
                          { value: 'price-ASC', label: 'Price: Low to High' },
                          { value: 'price-DESC', label: 'Price: High to Low' },
                          // { value: 'createDateTime-DESC', label: 'Latest' },
                          // { value: 'createDateTime-ASC', label: 'Older' },
                        ]}
                        onChange={(value) => {
                          reset();
                          setSelectedValue(value);
                          called.current = false;
                          doRefresh(0, value);
                        }}
                        placeholder="Sort"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="nft-cards">
                <div className="row">
                  {loading ? (
                    <p className="text-center m-5">Loading...</p>
                  ) : null}
                  {!loading && (records?.length <= 0 || !records) ? (
                    <p className="text-center mt-5 mb-5">
                      No NFT has been added to marketplace yet.
                    </p>
                  ) : null}

                  {records?.map((r) => {
                    const record = {
                      ...r?.nftDetails,
                      user: r?.ownerUser,
                      marketplace: r,
                    };
                    return <NFTCard record={record} />;
                  })}
                </div>
              </div>
              <div className="nft-cards-view-more">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    called.current = false;
                    doRefresh(records?.length > 0 ? skip + 1 : 0);
                  }}
                >
                  View More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
