import { useCallback, useContext, useEffect, useState } from 'react';
import API from '../api';
import AuthContext from '../context/authContext';

const useGetNFTIds = (userName) => {
  const { userData } = useContext(AuthContext);
  const [tokenIds, setTokenIds] = useState({});
  const [loading, setLoading] = useState(false);

  const doRefresh = useCallback(async () => {
    try {
      setLoading(true);
      let url = `/user/nfts?where[currentOwner]=${userData?.accountAddress}&skip=0&take=5000&select[]=nftId&select[]=id`;
      const response = await API.get(url);
      setTokenIds(response?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [userName]);

  useEffect(() => {
    doRefresh();
  }, [doRefresh, userName]);

  return {
    tokenIds,
    loading,
    doRefresh,
  };
};
export default useGetNFTIds;
