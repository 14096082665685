import React, { useContext, useEffect, useRef } from 'react';
import tradingNft from '../../assets/img/trading-nft.png';
import auctionNft from '../../assets/img/auction-nft.png';
import deductedProfilePage from '../../assets/img/dedicated-profile-page.png';
import rentNft from '../../assets/img/rent-nft.png';
import freeToPlayGames from '../../assets/img/free-to-play-games.png';
import earnRoyalties from '../../assets/img/earn-royalties.png';
import fantasyMobileCenter from '../../assets/img/phone-1.png';
import fantasyMobileLeft from '../../assets/img/phone-2.png';
import fantasyMobileRight from '../../assets/img/phone-3.png';
import fantasySportsLogo from '../../assets/img/fantasy.svg';
import p2pGamesBolt from '../../assets/img/bolt.svg';
import p2pGamesGround from '../../assets/img/ground.png';
import p2pGamesLeftCard from '../../assets/img/card-1.png';
import p2pGamesRightCard from '../../assets/img/card-2.png';
import cricketGame from '../../assets/img/cricket.jpg';
import SoccerGame from '../../assets/img/soccer.jpg';
import shape1 from '../../assets/img/shape-1.png';
import shape2 from '../../assets/img/shape-2.png';
import shape3 from '../../assets/img/shape-3.png';
import shape4 from '../../assets/img/shape-4.png';
import shape5 from '../../assets/img/shape-5.png';
import shape6 from '../../assets/img/shape-6.png';
import shape7 from '../../assets/img/shape-7.png';
import shape8 from '../../assets/img/shape-8.png';
import welcomeBannerImg from '../../assets/img/welcome-banner.jpg';
import welcomeBannerVideo from '../../assets/img/welcome-banner.mp4';
import routes from '../../constants/routesConstants';
import { Link, useLocation } from 'react-router-dom';
import useIntersection from '../../hook/useIntersection';
import useEmailSubscription from '../../hook/useEmailSubscription';
import ThemeContext from '../../context/themeContext';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion } from 'framer-motion';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

const Home = () => {
  gsap.registerPlugin(ScrollTrigger);
  const { loading, email, setEmail, subscribe } = useEmailSubscription();
  const fantasySportRef = useRef(null);
  const p2pGamesGroundRef = useRef(null);
  const p2pGamesBoltRef = useRef(null);
  const p2pGamesLeftCardRef = useRef(null);
  const p2pGamesRightCardRef = useRef(null);
  const inView = useIntersection(fantasySportRef);
  const shapeRef = useRef(null);
  const location = useLocation();
  const { toggleNavbar, isNavbarOpen } = useContext(ThemeContext);

  useEffect(() => {
    if (isNavbarOpen) {
      toggleNavbar();
    }
  }, [location]);

  const p2pGamesWrapMove = (e) => {
    p2pGamesGroundRef.current.style.transform =
      'translate3d(' +
      (e.pageX * -1) / 85 +
      'px,' +
      (e.pageY * -1) / 250 +
      'px, 0)';

    p2pGamesBoltRef.current.style.transform =
      'translate3d(' +
      (e.pageX * -1) / 40 +
      'px,' +
      (e.pageY * -1) / 100 +
      'px, 0)';

    p2pGamesLeftCardRef.current.style.transform =
      'translate3d(' +
      (e.pageX * -1) / 100 +
      'px,' +
      (e.pageY * -1) / 225 +
      'px, 0)';

    p2pGamesRightCardRef.current.style.transform =
      'translate3d(' +
      (e.pageX * -1) / 60 +
      'px,' +
      (e.pageY * -1) / 225 +
      'px, 0)';
  };

  const wrapRef = useRef(null);
  useEffect(() => {
    const element = wrapRef.current;
    let welcomeBannerStrip = gsap.timeline();
    welcomeBannerStrip.to(element.querySelector('.welcome-banner-strip p'), {
      xPercent: -15,
      ease: 'linear',
      scrollTrigger: {
        trigger: element.querySelector('.welcome-banner-sec'),
        scrub: 1,
        start: 'top top',
        end: 'bottom 0%',
      },
    });
    welcomeBannerStrip.to(element.querySelector('.welcome-banner-strip p'), {
      x: -1200,
      duration: 25,
      repeat: -1,
      ease: 'linear',
    });
    let simulationGamesShapes = gsap.timeline();
    simulationGamesShapes
      .to(element.querySelector('.simulation-games-shape.shape-1'), {
        x: 35,
        y: 25,
        duration: 3,
        repeat: -1,
        yoyo: true,
        ease: 'linear',
      })
      .to(
        element.querySelector('.simulation-games-shape.shape-2'),
        {
          x: 20,
          y: 25,
          duration: 2.5,
          repeat: -1,
          yoyo: true,
          ease: 'linear',
        },
        '-=0.9'
      )
      .to(
        element.querySelector('.simulation-games-shape.shape-3'),
        {
          x: 25,
          y: 10,
          duration: 3,
          repeat: -1,
          yoyo: true,
          ease: 'linear',
        },
        '-=1.1'
      )
      .to(
        element.querySelector('.simulation-games-shape.shape-4'),
        {
          x: 14,
          y: 30,
          duration: 3,
          repeat: -1,
          yoyo: true,
          ease: 'linear',
        },
        '-=1.5'
      )
      .to(
        element.querySelector('.simulation-games-shape.shape-5'),
        {
          x: 15,
          y: 20,
          duration: 2,
          repeat: -1,
          yoyo: true,
          ease: 'linear',
        },
        '-=1.3'
      )
      .to(
        element.querySelector('.simulation-games-shape.shape-6'),
        {
          x: 25,
          y: 10,
          duration: 2.5,
          repeat: -1,
          yoyo: true,
          ease: 'linear',
        },
        '-=2'
      )
      .to(
        element.querySelector('.simulation-games-shape.shape-7'),
        {
          x: 15,
          y: 35,
          duration: 3,
          repeat: -1,
          yoyo: true,
          ease: 'linear',
        },
        '-=2'
      )
      .to(
        element.querySelector('.simulation-games-shape.shape-8'),
        {
          x: 35,
          y: 15,
          duration: 2,
          repeat: -1,
          yoyo: true,
          ease: 'linear',
        },
        '-=2'
      );
  }, []);

  return (
    <div ref={wrapRef}>
      <section className="welcome-banner-sec">
        <video poster={welcomeBannerImg} autoPlay muted loop playsInline>
          <source src={welcomeBannerVideo} type="video/mp4" />
        </video>
        <div className="container">
          <div className="welcome-banner-con">
            <div className="sec-head text-center mb-0">
              <motion.h1
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="sec-title"
              >
                Welcome to <span>CUBIX</span> World
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                Unlimited Fun, Different Sports to Play & Earning opportunities
              </motion.p>
            </div>
          </div>
        </div>
        <div className="welcome-banner-strip">
          <p>
            collect &#9733; earn &#9733; play &#9733; collect &#9733; earn
            &#9733; play &#9733; collect &#9733; earn &#9733; play &#9733;
            collect &#9733; earn &#9733; play &#9733; collect &#9733; earn
            &#9733; play
          </p>
        </div>
      </section>
      <section
        className="nft-marketplace-sec common-sec"
        id="nft-marketplace-sec"
      >
        <div className="container">
          <div className="sec-head text-center">
            <motion.h2
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="sec-title"
            >
              <span>NFT</span> Marketplace
            </motion.h2>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="nft-marketplace-boxes"
          >
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                  className="nft-marketplace-box"
                >
                  <div className="nft-marketplace-img">
                    <motion.img
                      initial={{ scale: 0.6 }}
                      whileInView={{ scale: 1 }}
                      transition={{ duration: 0.5, delay: 0.7 }}
                      src={tradingNft}
                      alt=""
                    />
                  </div>
                  <div className="nft-marketplace-box-con">
                    <h3 className="nft-marketplace-box-title">Trading NFT:</h3>
                    <p>
                      The CUBIX NFT Marketplace is the platform where the
                      players can trade their NFT player cards.
                    </p>
                  </div>
                </motion.div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.7 }}
                  className="nft-marketplace-box"
                >
                  <div className="nft-marketplace-img">
                    <motion.img
                      initial={{ scale: 0.6 }}
                      whileInView={{ scale: 1 }}
                      transition={{ duration: 0.5, delay: 0.9 }}
                      src={auctionNft}
                      alt=""
                    />
                  </div>
                  <div className="nft-marketplace-box-con">
                    <h3 className="nft-marketplace-box-title">
                      Auctioning NFT’s:
                    </h3>
                    <p>
                      NFT owners will be able to sell their NFT cards, accept
                      and reject offers from other CUBIX users, and hold their
                      own auctions.
                    </p>
                  </div>
                </motion.div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.9 }}
                  className="nft-marketplace-box"
                >
                  <div className="nft-marketplace-img">
                    <motion.img
                      initial={{ scale: 0.6 }}
                      whileInView={{ scale: 1 }}
                      transition={{ duration: 0.5, delay: 1.1 }}
                      src={deductedProfilePage}
                      alt=""
                    />
                  </div>
                  <div className="nft-marketplace-box-con">
                    <h3 className="nft-marketplace-box-title">
                      Dedicated Profile Page:
                    </h3>
                    <p>
                      Users will also have their own profile page, where they
                      will be able to showcase their NFT collection.
                    </p>
                  </div>
                </motion.div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 1.1 }}
                  className="nft-marketplace-box"
                >
                  <div className="nft-marketplace-img">
                    <motion.img
                      initial={{ scale: 0.6 }}
                      whileInView={{ scale: 1 }}
                      transition={{ duration: 0.5, delay: 1.3 }}
                      src={rentNft}
                      alt=""
                    />
                  </div>
                  <div className="nft-marketplace-box-con">
                    <h3 className="nft-marketplace-box-title">Rent NFT’s:</h3>
                    <p>
                      Users will be able to rent their NFT cards for others who
                      wanted to use it to play games.
                    </p>
                  </div>
                </motion.div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 1.3 }}
                  className="nft-marketplace-box"
                >
                  <div className="nft-marketplace-img">
                    <motion.img
                      initial={{ scale: 0.6 }}
                      whileInView={{ scale: 1 }}
                      transition={{ duration: 0.5, delay: 1.5 }}
                      src={freeToPlayGames}
                      alt=""
                    />
                  </div>
                  <div className="nft-marketplace-box-con">
                    <h3 className="nft-marketplace-box-title">
                      Free To Play Games:
                    </h3>
                    <p>
                      There will be free to play games where you can win huge
                      rewards by owning the NFT.
                    </p>
                  </div>
                </motion.div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 1.5 }}
                  className="nft-marketplace-box"
                >
                  <div className="nft-marketplace-img">
                    <motion.img
                      initial={{ scale: 0.6 }}
                      whileInView={{ scale: 1 }}
                      transition={{ duration: 0.5, delay: 1.7 }}
                      src={earnRoyalties}
                      alt=""
                    />
                  </div>
                  <div className="nft-marketplace-box-con">
                    <h3 className="nft-marketplace-box-title">
                      Earn Royalties
                    </h3>
                    <p>
                      Being the First Buyer, you will be rewarded 0.5% of every
                      sale lifetime for your NFT.
                    </p>
                  </div>
                </motion.div>
              </div>
            </div>
            <div className="row">
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="col-12 text-center"
              >
                <Link className="btn-2 btn-primary" to={routes.Marketplace}>
                  Visit Marketplace
                </Link>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>
      <section
        className={`fantasy-sports-sec common-sec ${inView ? 'in-view' : ''}`}
        ref={fantasySportRef}
        id="fantasy-sports-sec"
      >
        <div className="container">
          <div className="fantasy-sports-logo">
            <motion.img
              initial={{ scale: 0.7 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 1 }}
              src={fantasySportsLogo}
              alt=""
            />
          </div>
          <div className="fantasy-sports-wrap">
            <div className="row">
              <div className="col-lg-6">
                <div className="fantasy-sports-con">
                  <div className="sec-head mb-0">
                    <motion.h2
                      initial={{ opacity: 0, y: -50 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                      className="sec-title"
                    >
                      <span>Build</span> lineups <br />
                      and <span>enter</span> contests
                    </motion.h2>
                    <motion.p
                      initial={{ opacity: 0, y: -40 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.2 }}
                    >
                      Create teams with cards from your collection and earn
                      points based on your players’ real-life performances to
                      compete in a variety of fantasy competitions each week.
                    </motion.p>
                  </div>
                  <motion.a
                    initial={{ opacity: 0, y: -30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    href="#"
                    className="btn-2 btn-primary"
                  >
                    Play Now
                  </motion.a>
                </div>
              </div>
              <div className="col-lg-6 text-center">
                <div className="fantasy-sports-mobile">
                  <div className="fantasy-sports-mobile-left">
                    <img src={fantasyMobileLeft} alt="" />
                  </div>
                  <div className="fantasy-sports-mobile-center">
                    <img src={fantasyMobileCenter} alt="" />
                  </div>
                  <div className="fantasy-sports-mobile-right">
                    <img src={fantasyMobileRight} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p2p-games-sec common-sec" id="p2p-games-sec">
        <div className="container">
          <div className="p2p-games-wrap">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="p2p-games-con">
                  <div className="sec-head">
                    <motion.h2
                      initial={{ opacity: 0, y: 100 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.4 }}
                      className="sec-title"
                    >
                      P2P <span>Card Games</span>
                    </motion.h2>
                    <motion.p
                      initial={{ opacity: 0, y: 80 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.4, delay: 0.2 }}
                    >
                      We will be launching Player To Player NFT Trading Card
                      Games 1v1, 2v2, 4v4 and so on. The games will be
                      cross-platform online games that can be played on
                      PC/Mobile devices.
                    </motion.p>
                    <motion.p
                      initial={{ opacity: 0, y: 60 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.4, delay: 0.2 }}
                    >
                      In this game you can collect cards by defeating other
                      players or buying them from the store.
                    </motion.p>
                    <motion.p
                      initial={{ opacity: 0, y: 40 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.4, delay: 0.4 }}
                    >
                      The new feature of our trading card game is that it's
                      built on blockchain technology where all transactions are
                      recorded and secured in a public ledger called a
                      blockchain. This means that the ownership of each card is
                      verified and documented in the blockchain ledger.
                    </motion.p>
                  </div>
                  <motion.a
                    initial={{ opacity: 0, y: 25 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.1 }}
                    href="https://p2p.cubixpro.world/"
                    target={'_blank'}
                    className="btn-2 btn-primary"
                  >
                    Play
                  </motion.a>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="p2p-games-ground-wrap"
                  onMouseMove={(e) => p2pGamesWrapMove(e)}
                >
                  <motion.div
                    initial={{
                      opacity: 0,
                      y: 70,
                      x: -50,
                      scale: 0.7,
                      rotateX: 90,
                      rotateY: -40,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      y: 0,
                      scale: 1,
                      rotateX: 0,
                      rotateY: 0,
                    }}
                    transition={{ duration: 0.2, delay: 0.5 }}
                    className="p2p-games-left-card p2p-games-card"
                    ref={p2pGamesLeftCardRef}
                  >
                    <img src={p2pGamesLeftCard} alt="" />
                  </motion.div>
                  <motion.div
                    initial={{
                      opacity: 0,
                      y: 100,
                      scale: 0.7,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: -20,
                      y: 0,
                      scale: 1,
                    }}
                    transition={{ duration: 0.2, delay: 0.5 }}
                    className="p2p-games-bolt"
                    ref={p2pGamesBoltRef}
                  >
                    <img src={p2pGamesBolt} alt="" />
                  </motion.div>
                  <motion.div
                    initial={{
                      opacity: 0,
                      y: 70,
                      x: 50,
                      scale: 0.7,
                      rotateX: 90,
                      rotateY: 40,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      y: 0,
                      scale: 1,
                      rotateX: 0,
                      rotateY: 0,
                    }}
                    transition={{ duration: 0.2, delay: 0.5 }}
                    className="p2p-games-right-card p2p-games-card"
                    ref={p2pGamesRightCardRef}
                  >
                    <img src={p2pGamesRightCard} alt="" />
                  </motion.div>
                  <motion.div
                    initial={{ scale: 0.7, opacity: 0.5 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className="p2p-games-ground"
                    ref={p2pGamesGroundRef}
                  >
                    <img src={p2pGamesGround} alt="" />
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="simulation-games-sec common-sec"
        ref={shapeRef}
        id="simulation-games-sec"
      >
        <div className="container">
          <div className="sec-head text-center">
            <motion.h2
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              className="sec-title"
            >
              <span>Simulation</span> Games
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 80 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2, delay: 0.2 }}
            >
              Play games, earn rewards. Prove yourself the best in leagues and
              tournaments with daily leaderboards. And challenge friends for
              rivalry and for fun.
            </motion.p>
          </div>
          <div className="simulation-games-wrap">
            <div className="row">
              <div className="col-lg-4">
                <div className="simulation-games-shapes">
                  <div className="simulation-games-shape shape-1">
                    <img src={shape1} alt="" />
                  </div>
                  <div className="simulation-games-shape shape-2">
                    <img src={shape2} alt="" />
                  </div>
                  <div className="simulation-games-shape shape-3">
                    <img src={shape3} alt="" />
                  </div>
                  <div className="simulation-games-shape shape-4">
                    <img src={shape4} alt="" />
                  </div>
                  <div className="simulation-games-shape shape-5">
                    <img src={shape5} alt="" />
                  </div>
                  <div className="simulation-games-shape shape-6">
                    <img src={shape6} alt="" />
                  </div>
                  <div className="simulation-games-shape shape-7">
                    <img src={shape7} alt="" />
                  </div>
                  <div className="simulation-games-shape shape-8">
                    <img src={shape8} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="simulation-games-con">
                  <div className="row">
                    <div className="col-sm-6">
                      <motion.div
                        initial={{ opacity: 0, x: -60, y: 150, scale: 0.6 }}
                        whileInView={{ x: 0, y: 0, scale: 1, opacity: 1 }}
                        transition={{ duration: 0.4 }}
                        className="simulation-games-box"
                      >
                        <div className="simulation-games-box-img">
                          <img src={cricketGame} alt="" />
                        </div>
                        <span className="simulation-games-box-title">
                          Cricket
                        </span>
                      </motion.div>
                    </div>
                    <div className="col-sm-6">
                      <motion.div
                        initial={{ opacity: 0, x: -60, y: 150, scale: 0.6 }}
                        whileInView={{ x: 0, y: 0, scale: 1, opacity: 1 }}
                        transition={{ duration: 0.2, delay: 0.4 }}
                        className="simulation-games-box"
                      >
                        <div className="simulation-games-box-img">
                          <img src={SoccerGame} alt="" />
                        </div>
                        <span className="simulation-games-box-title">
                          Soccer
                        </span>
                      </motion.div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <motion.a
                        initial={{ opacity: 0, y: 25 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        href="#"
                        className="btn-2 btn-primary"
                      >
                        Coming Soon
                      </motion.a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="nft-marketplace-sec common-sec mb-5"
        id="cubix-token-details"
      >
        <div className="container">
          <div className="sec-head text-center">
            <motion.h2
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              className="sec-title"
            >
              <span>$CUBIX</span> Token
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 80 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2, delay: 0.2 }}
            >
              The CUBIX token is the engine that drives the CUBIX ecosystem.
              When you win your matches, you're rewarded with CUBIX tokens. But
              its more than just a token for buying and selling you player NFTs.
            </motion.p>
          </div>

          <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-item-center mt-2">
              <CopyToClipboard
                text={'0xa0e5c8b2b2e345c72f452880b2c164b944012907'}
                onCopy={() => {
                  toast.success('CUBIX token Smart address copied!');
                }}
              >
                <img
                  role={'button'}
                  alt="cubix contract address"
                  src="/images/smartcontract-btn.svg"
                />
              </CopyToClipboard>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-item-center mt-2">
              <a
                target={'_blank'}
                href="https://cubix.gitbook.io/cubix-white-paper/"
                className="text-center"
              >
                <img alt="whitepaper" src="/images/whitepaper-btn.svg" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="subscribe-newsletter-sec common-sec pt-0">
        <div className="container">
          <div className="subscribe-newsletter-wrap">
            <div className="sec-head text-center">
              <motion.h2
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                className="sec-title"
              >
                Subscribe To Our Newsletter
              </motion.h2>
            </div>
            <motion.div
              className="form-group"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control"
                placeholder="Enter your e-mail address"
              />
              <button
                type="submit"
                className="btn-2 btn-primary"
                onClick={() => subscribe()}
              >
                {loading ? 'Please wait...' : 'Subscribe'}
              </button>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
